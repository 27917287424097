$(function () {

   $(document).on('mouseover.drop-down', '*[data-drop-down=hover], *[data-drop-down=click]', function () {
      return window.clearTimeout($(this).data('drop-down.closeTimer'));
   });

   $(document).on('mouseenter.drop-down:hover', '*[data-drop-down=hover]', function () {
      var $this, openTimer;
      $this = $(this);
      openTimer = window.setTimeout(function () {
         var options;
         options = $this.data('options') || {
            duration: 100
         };

         $('.drop-down-box').fadeIn();

         return $this.find('*[data-drop-down=box]:first').fadeIn(options.duration);
      }, 100);
      return $this.data('drop-down.openTimer', openTimer);
   });

   $(document).on('mouseleave.drop-down:hover', '*[data-drop-down=hover]', function () {
      var $this, closeTimer;
      window.clearTimeout($(this).data('drop-down.openTimer'));
      $this = $(this);
      if ($this.data('drop-down') === 'locked') {
         return;
      }
      closeTimer = window.setTimeout(function () {
         var options;
         options = $this.data('options') || {
            duration: 100
         };
         return $this.find('*[data-drop-down=box]:first').fadeOut(options.duration);
      }, 100);
      return $this.data('drop-down.closeTimer', closeTimer);
   });

   $(document).on('click.drop-down:click', '*[data-drop-down=click] *[data-trigger]', function () {
      var $box, $wrapper, options;
      $wrapper = $(this).parents('*[data-drop-down=click]:first');
      $box = $wrapper.find('*[data-drop-down=box]:first');
      options = $wrapper.data('options') || {
         duration: 100
      };
      if ($box.is(':hidden')) {
         $box.fadeIn(options.duration, function () {
            return $box.find('*[data-auto-focus]').focus();
         });
      } else {
         $box.fadeOut(options.duration);
      }
      return false;
   });

   return $(document).on('mouseleave.drop-down:click', '*[data-drop-down=click]', function () {
      var $this, closeTimer;
      $this = $(this);
      closeTimer = window.setTimeout(function () {
         var options;
         options = $this.data('options') || {
            duration: 100
         };
         return $this.find('*[data-drop-down=box]:first').fadeOut(options.duration);
      }, 400);
      return $this.data('drop-down.closeTimer', closeTimer);
   });

});


$(function () {

  $(document).on('mouseover.drop-down', '*[data-drop-down-sandbox=hover], *[data-drop-down-sandbox=click]', function () {
     return window.clearTimeout($(this).data('drop-down-sandbox.closeTimer'));
  });

  $(document).on('mouseenter.drop-down:hover', '*[data-drop-down-sandbox=hover]', function () {
     var $this, openTimer;
     $this = $(this);
     openTimer = window.setTimeout(function () {
        var options;
        options = $this.data('options') || {
           duration: 100
        };

        $('.drop-down-sand').fadeIn();

        return $this.find('*[data-drop-down-sandbox=box]:first').fadeIn(options.duration);
     }, 100);
     return $this.data('drop-down-sandbox.openTimer', openTimer);
  });

  $(document).on('mouseleave.drop-down:hover', '*[data-drop-down-sandbox=hover]', function () {
     var $this, closeTimer;
     window.clearTimeout($(this).data('drop-down-sandbox.openTimer'));
     $this = $(this);
     if ($this.data('drop-down-sandbox') === 'locked') {
        return;
     }
     closeTimer = window.setTimeout(function () {
        var options;
        options = $this.data('options') || {
           duration: 100
        };
        return $this.find('*[data-drop-down-sandbox=box]:first').fadeOut(options.duration);
     }, 100);
     return $this.data('drop-down-sandbox.closeTimer', closeTimer);
  });

  $(document).on('click.drop-down:click', '*[data-drop-down-sandbox=click] *[data-trigger]', function () {
     var $box, $wrapper, options;
     $wrapper = $(this).parents('*[data-drop-down-sandbox=click]:first');
     $box = $wrapper.find('*[data-drop-down-sandbox=box]:first');
     options = $wrapper.data('options') || {
        duration: 100
     };
     if ($box.is(':hidden')) {
        $box.fadeIn(options.duration, function () {
           return $box.find('*[data-auto-focus]').focus();
        });
     } else {
        $box.fadeOut(options.duration);
     }
     return false;
  });

  return $(document).on('mouseleave.drop-down:click', '*[data-drop-down-sandbox=click]', function () {
     var $this, closeTimer;
     $this = $(this);
     closeTimer = window.setTimeout(function () {
        var options;
        options = $this.data('options') || {
           duration: 100
        };
        return $this.find('*[data-drop-down-sandbox=box]:first').fadeOut(options.duration);
     }, 400);
     return $this.data('drop-down-sandbox.closeTimer', closeTimer);
  });

});